.card {
    @apply
    flex flex-col px-4 bg-white mt-2 w-full xl:w-11/12
    bg-[rgba(255,255,255,.1)] rounded-lg py-2 overflow-auto
    opacity-90 container -z-10
}

.card-title {
    @apply
    text-white flex items-start lg:items-center
    text-base font-bold
}

.card-content {
    color: #969b98;
    @apply
    text-sm font-medium
    mt-2
}

.sticker {
    @apply
    h-4 w-1 bg-[#26ad60] mr-2
}
.flexx{
    @apply
    flex items-center
}
