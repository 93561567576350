.header {
    @apply
    flex container
    mx-auto justify-between
    py-4  items-center
}

.header > img {
    @apply
    w-20 h-20
}

.peer {
    @apply
    px-5 py-2 text-white flex bg-transparent border-none
}

.buyuk-text {
    @apply
    text-xl
}

.bayrak {
    @apply
    self-center w-8 h-8
}

.bayrak-p {
    @apply flex self-center items-start w-auto ml-1
}

.drop-down-container {
    @apply
    hidden absolute peer-hover:flex hover:flex w-[120px]
    flex-col bg-white drop-shadow-lg items-center
}

.drop-down-content {
    @apply
    flex justify-between items-center gap-1
    w-full lg:px-4 px-2 py-4
    text-[#000]
}

details {
    @apply
    relative
    select-none
}

summary::marker {
    @apply hidden
}


.drop-down {
    @apply flex flex-col items-center justify-around z-50 w-32 lg:w-36 h-fit border border-gray absolute bg-white text-white rounded-lg px-0 right-8 lg:right-0
}

.drop-down-content {
    @apply
    flex justify-between items-center  gap-1
    w-full lg:px-4 px-4  py-4
    text-[#000] cursor-pointer
}

details > summary > .arrow-down {
    @apply
    transition-all
    duration-500
    rotate-0
}

details[open] > summary:before {
    content: "";
    @apply
    fixed
    top-0 left-0
    right-0 bottom-0
    transition-all
}

details[open] > summary > .arrow-down {
    @apply
    transition-all
    duration-500
    rotate-180
}


.arrow-down {
    @apply
    hidden lg:flex
    self-center
    cursor-pointer
    ml-2
}

::-webkit-scrollbar-thumb {
    @apply
    hidden
}

::-webkit-details-marker {
    @apply
    hidden
}
