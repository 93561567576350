.qr {
    width: 100%;
    @apply h-fit
    flex flex-col items-center
    xl:bg-[rgba(255,255,255,.1)]
    overflow-hidden mb-8 xl:mb-0
    rounded-lg
}

@media screen and (min-width: 1280px) {
    .qr {
        width: 115%;
    }
}

.paylas-container {
    @apply
    px-4
    flex  cursor-pointer text-white
}

.modal-title {
    @apply
    text-3xl font-semibold
}

.yanyana {
    @apply
    flex items-center
}

.saniye {
    @apply
    text-white mt-4
}

.uzaklastır-1 {
    @apply
    mx-1
}

.paylas {
    background: #26ad60;
    @apply
    px-2 rounded-r-lg
}

.baglanti {
    @apply
    flex items-center rounded-lg px-4 mt-4
}

.renk-beyaz {
    @apply
    text-white
}

.refresh {
    @apply
    w-[80%]
    absolute
    top-[50%] left-[50%] bg-white rounded-full
    transform translate-x-[-50%] translate-y-[-50%]
}

.qr-code {
    @apply
    bg-white  mt-4 relative
    rounded-lg p-4
    -z-10
}

.talimatlar {
    @apply
    flex flex-col text-white mt-4 px-2
}

.link {
    border: 1px solid rgba(255, 255, 255, .5);
    @apply px-2 border-l border-t rounded-l-lg border-b
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
    background: yellow;
    @apply
    w-[10px] h-[10px]  mr-2 rounded-full
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.mobile-footer {
    height: 9vh;
    @apply
    flex justify-around
    items-center
    fixed bottom-0 w-full
}


.mobile-footer-menu {
    width: 100%;
    @apply
    hidden rounded-3xl
    justify-center
    fixed
    bottom-0
    text-white z-50
}

.mobile-footer-menu::before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    @apply
    top-0 left-0
    h-full w-full
    -z-50 fixed
}

.open {
    @apply
    flex
}

.close {
    @apply
    hidden
}


.modal-buton {
    @apply
    text-white  font-bold  text-sm px-6 py-3
    rounded
}

.modal-container {
    @apply
    justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none
}

.modal-content {
    @apply
    relative w-auto my-6 mx-auto max-w-3xl
}

.modal-content-detay {
    @apply
    border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none
}

.modal-detay {
    @apply
    flex items-start justify-between p-5 border-b border-solid  rounded-t
}

.modal-detay-buton {
    @apply
    p-1 ml-auto bg-transparent border-0  opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none
}

.modal-detay-close {
    color: #000000;
}

.modal-icerik {
    @apply
    relative p-6 flex-auto
}

.modal-icerik > p {
    @apply
    my-4  text-lg leading-relaxed
}

.modal-footer-container {
    @apply
    flex items-center justify-end p-6 border-t border-solid  rounded-b
}

.modal-footer-buton {
    @apply
    font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
}

.modal {
    @apply
    opacity-25 fixed inset-0 z-40
}

.invalid-request {
    width: 100%;
}

@media screen and (min-width: 1280px) {
    .invalid-request {
        width: 120%;
    }
}
