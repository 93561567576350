.video-container{
    @apply
    flex flex-col-reverse xl:flex  xl:flex-row-reverse xl:justify-between container
    mx-auto
}
.flex-dik{
    @apply
    flex flex-col items-end
}

.iframee{
    @apply rounded-lg
    w-full xl:w-11/12 h-[300px] lg:h-[500px]
}
